<template>
  <div
    class="relative mb-1 overflow-hidden rounded-4xl border border-block-4 bg-block-1 px-6 py-6 shadow-md lg:px-16 lg:py-12"
  >
    <slot />

    <SvgSectionCloud
      fluid
      class="pointer-events-none absolute top-2/3 z-0 h-1/2 w-full bg-opacity-20"
    />
  </div>
</template>
